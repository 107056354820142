
.catering-form .form-control  {
  height: 65px;
  border: none;
}

.catering-form input {
  width: 400px !important;
}

.catering-form select {
  width: 200px !important;
}

.app-down-row {
  position: relative;
  height: 510px;
}

.app-down-row .cater-avatar {
  position: absolute;
  left: 0;
  top: -65px;
}

.app-btn a {
  margin-right: 10px;
}

.catering-list .post-single .post-desk {
  padding: 30px 0;
  height: 180px;
}

.app-down-row .app-info  {
  font-weight: 300;
}

.app-down-row .app-info span {
  font-weight: bold;
}

.float-left {
  float: left;
}

.txt-extra-xl {
  font-size: 70px;
}

.light-txt-trans {
  color: rgba(255,255,255,.5);
}

.f-menu-desk {
  color: #959595;
  margin-bottom: 15px;
}
