/*------------------------------------------------------------------
    accordions
-------------------------------------------------------------------*/


.accordion > dt{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.accordion.time-line > dt > a{
    background: none;
}
.accordion > dt > a{
    display: block;
    position: relative;
    color: #222222;
    text-decoration: none;
    padding: 14px 20px;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    /*font-family: 'Abel', sans-serif;*/
    letter-spacing: 2px;

}

.accordion > dt > a:hover {
    text-decoration: none;
}

.accordion > dt > a:hover,
.accordion > dt > a.active{
    color: #fff;
    border-color: #222;
    background: #222;
    cursor: pointer;
}

.accordion > dt > a:after {
    content: "\e648";
    width: 15px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    right: 10px;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    -webkit-font-smoothing: antialiased;
}

.accordion > dt > a.active:after,
.accordion > dt > a.active:hover:after{

    content: "\e641";
    color: #fff;
}

.accordion > dt > a:hover:after{
    color: #fff;
}

.accordion > dd{
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    font-size: 14px;
    line-height: 1.8;
    color: #777;
}


.accordion > dt > a,
.accordion > dt > a:after,
.toggle > dt > a,
.toggle > dt > a:after {
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}


/*------------------------------------------------------------------
    timeline accordions
-------------------------------------------------------------------*/

dl.accordion.time-line {
    position: relative;
}

dl.accordion.time-line:before {
    border-left: 1px dashed #cecece;
    bottom: 10px;
    content: "";
    left: 15px;
    position: absolute;
    top: 25px;
    z-index: 0;
}

.accordion.time-line > dd {
    margin-bottom: 10px;
    padding: 10px 20px 20px 40px;
    font-size: 14px;
    line-height: 1.8;
    color: #777;
}

.accordion.time-line > dt > a:after {
    content: "\e648";
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
    left: 0;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    font-size: 20px;
    text-align: center;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    background: #222;
    padding-left: 3px;
}

.accordion.time-line > dt > a.active:after,
.accordion.time-line > dt > a.active:hover:after {
    content: "\e641";
    color: #fff;
}


.accordion.time-line > dt > a {
    display: block;
    position: relative;
    color: #222222;
    text-decoration: none;
    padding: 14px 20px 14px 40px;
    border:none;
    /*font-family: 'Abel', sans-serif;*/
    letter-spacing: 2px;
}

.accordion.time-line > dt > a:hover,
.accordion.time-line > dt > a.active {
    background: none !important;
}


/*------------------------------------------------------------------
    Toggle
-------------------------------------------------------------------*/

.toggle > dt{
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.toggle > dt > a {
    display: block;
    position: relative;
    color: #222222;
    text-decoration: none;
    padding: 14px 20px;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    /*font-family: 'Abel', sans-serif;*/
    letter-spacing: 3px;
}

.toggle.time-line > dt > a {
    background: none;
}

.toggle > dt > a:hover{
    text-decoration: none;
}

.toggle > dt > a:hover,
.toggle > dt > a.active{
    color: #fff;
    border-color: #222;
    background: #222;
    cursor: pointer;
}

.toggle > dt > a:after {
    content: "\e648";
    width: 15px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    right: 10px;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    -webkit-font-smoothing: antialiased;
}
.toggle > dt > a.active:after,
.toggle > dt > a.active:hover:after{
    content: "\e641";
    color: #fff;
}

.toggle > dt > a:hover:after{
    color: #fff;
}

.toggle > dd{
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    font-size: 14px;
    line-height: 1.8;
    color: #777;
}


/*------------------------------------------------------------------
    timeline toggle
-------------------------------------------------------------------*/

dl.toggle.time-line {
    position: relative;
}

dl.toggle.time-line:before {
    /*background-color: #d1d1d1;*/
    border-left: 1px dashed #cecece;
    bottom: 10px;
    content: "";
    left: 15px;
    position: absolute;
    top: 25px;
    /*width: 1px;*/
    z-index: 0;
}

.toggle.time-line > dd {
    margin-bottom: 10px;
    padding: 10px 20px 20px 40px;
    font-size: 14px;
    line-height: 1.8;
    color: #777;
}

.toggle.time-line > dt > a:after {
    content: "\e648";
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
    left: 0;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    font-size: 20px;
    text-align: center;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    background: #222;
    padding-left: 3px;
}

.toggle.time-line > dt > a.active:after,
.toggle.time-line > dt > a.active:hover:after {
    content: "\e641";
    color: #fff;
}


.toggle.time-line > dt > a {
    display: block;
    position: relative;
    color: #222222;
    text-decoration: none;
    padding: 14px 20px 14px 40px;
    border:none;
    /*font-family: 'Abel', sans-serif;*/
    letter-spacing: 3px;
}

.toggle.time-line > dt > a:hover,
.toggle.time-line > dt > a.active {
    background: none !important;
}



