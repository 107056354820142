@keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.typist-blink:after {
  content: " ";
  display: inline-block;
  height: 47px;
  position: relative;
  top: 10px;
  margin-left: 3px;
  margin-right: 7px;
  border-right: 2px solid;
  animation: blink 1s step-start 0s infinite;
}

.typist-blink > .selectedText {
  display: none;
}

.typist-mark > .selectedText {
  color: #fff;
  background-color: #222;
  font-style: normal;
}