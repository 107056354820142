.top-bar {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #eee;
}

.top-bar .gray-bg {
  background: #f5f5f5;
}

.top-social-link a{
  color: #aaaaaa;
  display: inline-block;
  margin-right: 10px;
}


.top-social-link a:hover,
.top-link li a:hover {
  color: #222;
}

.top-link {
  padding: 0;
  margin: 0;
  list-style: none;
  float: right;
}

.top-link li {
  display: inline-block;
  margin:0 5px;
}

.top-link li i,
.top-link li a i{
  margin-right: 10px;
}

.top-link li,
.top-link li a{
  color: #aaaaaa;
}

.top-link.text-uppercase {
  font-size: 12px;
}


.top-bar .col-lg-1,
.top-bar .col-lg-10,
.top-bar .col-lg-11,
.top-bar .col-lg-12,
.top-bar .col-lg-2,
.top-bar .col-lg-3,
.top-bar .col-lg-4,
.top-bar .col-lg-5,
.top-bar .col-lg-6,
.top-bar .col-lg-7,
.top-bar .col-lg-8,
.top-bar .col-lg-9,
.top-bar .col-md-1,
.top-bar .col-md-10,
.top-bar .col-md-11,
.top-bar .col-md-12,
.top-bar .col-md-2,
.top-bar .col-md-3,
.top-bar .col-md-4,
.top-bar .col-md-5,
.top-bar .col-md-6,
.top-bar .col-md-7,
.top-bar .col-md-8,
.top-bar .col-md-9,
.top-bar .col-sm-1,
.top-bar .col-sm-10,
.top-bar .col-sm-11,
.top-bar .col-sm-12,
.top-bar .col-sm-2,
.top-bar .col-sm-3,
.top-bar .col-sm-4,
.top-bar .col-sm-5,
.top-bar .col-sm-6,
.top-bar .col-sm-7,
.top-bar .col-sm-8,
.top-bar .col-sm-9,
.top-bar .col-xs-1,
.top-bar .col-xs-10,
.top-bar .col-xs-11,
.top-bar .col-xs-12,
.top-bar .col-xs-2,
.top-bar .col-xs-3,
.top-bar .col-xs-4,
.top-bar .col-xs-5,
.top-bar .col-xs-6,
.top-bar .col-xs-7,
.top-bar .col-xs-8,
.top-bar .col-xs-9 {
  padding-right: 15px;
  padding-left: 15px;
}

.top-link.divider li:after{
  content: '/';
  padding-left: 13px;
  display: inline-block;
}

.top-link.divider li:last-child:after {
  content: '';
  display: inline-block;
}
