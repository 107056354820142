.video-banner {
  height:100vh;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.video-banner.overlay-background .container {
  z-index: 300;
  position: absolute;
}

.overlay-background{
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}
.overlay-background .container{
  z-index: 300;
  position: relative;
}

.overlay-block{
  width:100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,.7);
  z-index: 200;
}

.overlay-block-light{
  width:100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,.45);
  z-index: 200;
}

