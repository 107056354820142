.home-banner {
  background-image: url("../img/banner/index-banner-2.jpg");
  //background-image: url("../img/banner/home-banner1.jpg");
}

.creative-banner {
  background-image: url("../img/banner/creative-banner.jpg");
}

.banner-catering {
  background-image: url("../img/banner/catering.jpg");
}

.banner-hotel {
  background-image: url("../img/hotel/hotel_banner.jpg");
}

.banner-ban2 {
  background-image: url("../img/banner/ban2.jpg");
}

.banner-01 {
  background-image: url("../img/banner/01.jpg");
}

.banner-02 {
  background-image: url("../img/banner/02.jpg");
}

.banner-04 {
  background-image: url("../img/banner/04.jpg");
}

.banner-12 {
  background-image: url("../img/banner/12.jpg");
}

.banner-13 {
  background-image: url("../img/banner/13.jpg");
}

.banner-14 {
  background-image: url("../img/banner/14.jpg");
}

.banner-15 {
  background-image: url("../img/banner/15.jpg");
}

.banner-16 {
  background-image: url("../img/banner/16.jpg");
}

.banner-17 {
  background-image: url("../img/banner/17.jpg");
}

.banner-19 {
  background-image: url("../img/banner/19.jpg");
}

.banner-20 {
  background-image: url("../img/banner/20.jpg");
}

.banner-22 {
  background-image: url("../img/banner/22.jpg");
}

.banner-23 {
  background-image: url("../img/banner/23.jpg");
}

.banner-24 {
  background-image: url("../img/banner/24.jpg");
}

.banner-25 {
  background-image: url("../img/banner/25.jpg");
}

.banner-26 {
  background-image: url("../img/banner/26.jpg");
}

.banner-27 {
  background-image: url("../img/banner/27.jpg");
}

.banner-28 {
  background-image: url("../img/banner/28.jpg");
}

.banner-07 {
  background-image: url("../img/banner/07.jpg");
}

.banner-08 {
  background-image: url("../img/banner/08.jpg");
}

.banner-09 {
  background-image: url("../img/banner/09.jpg");
}

.banner-10 {
  background-image: url("../img/banner/10.jpg");
}

.banner-11 {
  background-image: url("../img/banner/11.jpg");
}

.banner-05 {
  background-image: url("../img/banner/05.jpg");
}

.banner-06 {
  background-image: url("../img/banner/06.jpg");
}

.banner-p {
  background-image: url("../img/product/p-banner.jpg");
}

.banner-r {
  background-image: url("../img/real-state/r-banner.jpg");
}

.banner-spa {
  background-image: url("../img/spa/spa-banner.jpg");
}

.banner-box1 {
  background-image: url("../img/banner/box_banner1.jpg");
}

.banner-box2 {
  background-image: url("../img/banner/box_banner2.jpg");
}

.banner-box-i {
  background-image: url("../img/banner/box_banner.jpg");
}

.banner-flex3 {
  background-image: url("../img/banner/flex/flex3.jpg");
}

.banner-demo {
  background-image: url("../img/banner/demo.jpg");
}

.banner-onedot {
  background-image: url("../img/banner/onedot.jpg");
}

.banner-02p {
  background-image: url("../img/banner/02p.jpg");
}

.banner-app {
  background-image: url("../img/app/app_banner.jpg");
}

.banner-para {
  background-image: url("../img/parallax/para_banner.jpg");
}

.banner-para1 {
  background-image: url("../img/parallax/para1.jpg");
}

.banner-para2 {
  background-image: url("../img/parallax/para2.jpg");
}

.banner-para3 {
  background-image: url("../img/parallax/para3.jpg");
}

.banner-para4 {
  background-image: url("../img/parallax/para4.jpg");
}

.banner-para5 {
  background-image: url("../img/parallax/para5.jpg");
}

.banner-para6 {
  background-image: url("../img/parallax/para6.jpg");
}

.banner-para7 {
  background-image: url("../img/parallax/para7.jpg");
}

.banner-para8 {
  background-image: url("../img/parallax/para8.jpg");
}

.banner-sb1 {
  background-image: url("../img/about/sb-1.jpg");
}

.banner-03 {
  background-image: url("../img/about/03.jpg");
}

.banner-contact {
  background-image: url("../img/contact-banner.jpg");
}

/*--------------------------------------
    parallax images
--------------------------------------*/

.parallax-4 {
  background-image: url("../img/parallax/4.jpg");
}

.parallax-5 {
  background-image: url("../img/parallax/5.jpg");
}

.parallax-6 {
  background-image: url("../img/parallax/6.jpg");
}

.parallax-7 {
  background-image: url("../img/parallax/7.jpg");
}

.parallax-8 {
  background-image: url("../img/parallax/8.jpg");
}

.parallax-9 {
  background-image: url("../img/parallax/9.jpg");
}

.parallax-10 {
  background-image: url("../img/parallax/10.jpg");
}

.parallax-11 {
  background-image: url("../img/parallax/11.jpg");
}

.parallax-12 {
  background-image: url("../img/parallax/12.jpg");
}

.parallax-13 {
  background-image: url("../img/parallax/13.jpg");
}

.parallax-14 {
  background-image: url("../img/parallax/14.jpg");
}

.parallax-15 {
  background-image: url("../img/parallax/15.jpg");
}

.parallax-16 {
  background-image: url("../img/parallax/16.jpg");
}

.parallax-17 {
  background-image: url("../img/parallax/17.jpg");
}

.parallax-18 {
  background-image: url("../img/parallax/18.jpg");
}

.parallax-19 {
  background-image: url("../img/parallax/19.jpg");
}

.parallax-p15 {
  background: url("../img/post/p15.jpg") center center no-repeat;
  background-size: cover;
  height: 599px;
}

.fast-food {
  background: url("../img/restaurant/fast-food.jpg") right 30px no-repeat;
}

.parallax-hot {
  background-image: url("../img/hotel/hotparallax.jpg");
}

.parallax-ev-p {
  background-image: url("../img/parallax/ev-p.jpg");
}

.parallax-p {
  background-image: url("../img/product/p-parallax.jpg");
}

.parallax-r {
  background-image: url("../img/real-state/r-parallax.jpg");
}

.parallax-r-2 {
  background-image: url("../img/real-state/r-parallax-2.jpg");
}

.parallax-s {
  background-image: url("../img/spa/s-parallax.jpg");
}

/**/

.post-p8 {
  background: url("../img/post/p8.jpg") right 100px no-repeat;
}

.post-p9 {
  background: url("../img/post/p9.jpg") left 150px no-repeat;
}

.post-p9-alt {
  background: url("../img/post/p9.jpg") left 100px no-repeat;
}


