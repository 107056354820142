.amenities-list li:last-child {
  border-bottom: 1px solid #9e9e9e;
}

.amenities-list li {
  border-top: 1px solid #9e9e9e;
  padding: 10px 0;
}

.hotel-intro-box-resize .post-single {
  width: 250px;
}
