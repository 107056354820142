.magazine-slider {
  margin-top: 0;
}

.magazine-slider img {
  height: 424px;
}

.magazine-slider .container {
  width: auto;
}

.magazine-grid .row {
  margin-right: -10px;
  margin-left: -10px;
}

.magazine-grid .col-lg-1,
.magazine-grid .col-lg-10,
.magazine-grid .col-lg-11,
.magazine-grid .col-lg-12,
.magazine-grid .col-lg-2,
.magazine-grid .col-lg-3,
.magazine-grid .col-lg-4,
.magazine-grid .col-lg-5,
.magazine-grid .col-lg-6,
.magazine-grid .col-lg-7,
.magazine-grid .col-lg-8,
.magazine-grid .col-lg-9,
.magazine-grid .col-md-1,
.magazine-grid .col-md-10,
.magazine-grid .col-md-11,
.magazine-grid .col-md-12,
.magazine-grid .col-md-2,
.magazine-grid .col-md-3,
.magazine-grid .col-md-4,
.magazine-grid .col-md-5,
.magazine-grid .col-md-6,
.magazine-grid .col-md-7,
.magazine-grid .col-md-8,
.magazine-grid .col-md-9,
.magazine-grid .col-sm-1,
.magazine-grid .col-sm-10,
.magazine-grid .col-sm-11,
.magazine-grid .col-sm-12,
.magazine-grid .col-sm-2,
.magazine-grid .col-sm-3,
.magazine-grid .col-sm-4,
.magazine-grid .col-sm-5,
.magazine-grid .col-sm-6,
.magazine-grid .col-sm-7,
.magazine-grid .col-sm-8,
.magazine-grid .col-sm-9,
.magazine-grid .col-xs-1,
.magazine-grid .col-xs-10,
.magazine-grid .col-xs-11,
.magazine-grid .col-xs-12,
.magazine-grid .col-xs-2,
.magazine-grid .col-xs-3,
.magazine-grid .col-xs-4,
.magazine-grid .col-xs-5,
.magazine-grid .col-xs-6,
.magazine-grid .col-xs-7,
.magazine-grid .col-xs-8,
.magazine-grid .col-xs-9 {
  padding-left:10px;
  padding-right:10px;
}

.aside-social {
  border: 1px solid #ddd;
  font-size: 0;
  margin-bottom: 20px;
}

.aside-social li {
  border-left: 1px solid #ddd;
  padding-top: 8px;
  text-align: center;
  transition: background 0.3s ease 0s;
  width: 25%;
}

.aside-social li:hover {
  border-left: 1px solid #333;
  background: #333;
  transition: background 0.3s ease 0s;
  color: #fff;
}

.aside-social li:hover i,
.aside-social li:hover p {
  color: #fff;
  transition:  0.3s ease 0s;
}

.aside-social li:first-child {
  border-left: medium none;
}

.aside-social.list-inline > li {
  display: inline-block;
}

.aside-social.list-inline {
  margin-left: 0;
}

.aside-social li i {
  color: #aaa;
  font-size: 16px;
  margin-top: 5px;
  transition:  0.3s ease 0s;
}

.aside-social li p {
  font-size: 11px;
  margin-bottom: 6px;
  margin-top: 5px;
  transition:  0.3s ease 0s;
}


.newsletter {
  background: url("../img/parallax/3.jpg") repeat 0 0 / cover ;
  color: #fff;
  margin-bottom: 20px;
  padding: 20px;
}

.newsletter h3 {
  margin-bottom: 10px;
}

.newsletter p {
  line-height: 20px;
}

.round-less {
  border-radius: 0;
}

.magazine-grid .post-single .post-desk {
  height: auto;
}

.magazine-latest-news {
  background: #eeeeee;
  padding: 20px;
  position: relative;
}

.breaking {
  float: left;
  margin-right: 30px;
  background: #333;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  text-transform: uppercase;
}

.breaking:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51, 51, 51, 0);
  border-left-color: #333;
  border-width: 10px;
  margin-top: -10px;
}

.magazine-latest-news .news-slider {
  margin:0  150px 0 130px;
}

.magazine-latest-news .flex-control-nav {
  bottom: 25px;
}

.magazine-latest-news a {
  color: #333;
}
