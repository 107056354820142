@import "../abstract/variable";

/*------------------------------------------------------------------
Default theme  Stylesheet
-------------------------------------------------------------------*/
::selection {
    background: $theme-color-default;
    color: #FFF;
    text-shadow: none;
}

::-moz-selection {
    background: $theme-color-default; /* Firefox */
    color: #FFF;
    text-shadow: none;
}

::-webkit-selection {
    background: $theme-color-default; /* Safari */
    color: #FFF;
    text-shadow: none;
}

a,
.menuzord-menu>li.active>a,
.menuzord-menu>li:hover>a,
.portfolio-with-title .portfolio-title h4 a:hover,
.f-link:hover,
#footer .f-list li a:hover,
.featured-item.border-box:hover .icon i,
.post-single .post-cat li a:hover,
.post-single .post-desk h4 a:hover,
.post-single .post-desk a.author:hover,
.post-list .post-single .post-cat li a,
.portfolio-description a:hover,
.portfolio-title a:hover,
.post-sub-title,
.team-member .s-link a:hover,
.t-s-link a:hover,
.price-col.feature h1,
.widget-category li a:hover,
.widget-comments li a:hover,
.blog-classic .blog-post h4 a:hover,
.post-meta li a:hover,
.widget-latest-post .w-desk a:hover,
.comments-list .comment-author a:hover,
.comments-list .comment-item-data a:hover ,
.product-title h5 a:hover,
.comments-list .comment-info a:hover,
.portfolio-with-title.blog-m .portfolio-title .date a:hover,
.comments-list .comment-info .comment-author a:hover,
.icon-tabs .nav-pills>li>a:hover,
.icon-tabs .nav-pills>li.active>a,
.icon-tabs .nav-pills>li.active>a:focus,
.icon-tabs .nav-pills>li.active>a:hover,
.cd-nav .cd-primary-nav li.selected a,
.no-touch .cd-nav .cd-primary-nav a:hover,
.intro-portfolio a:hover{
    color: $theme-color-default ;
}

.theme-color
{
    color: $theme-color-default !important;
}

.theme-bg,
.massive-progress.progress-theme-color .progress-bar,
.massive-progress-alt.progress-theme-color .progress-bar,
.ei-slider-thumbs li.ei-slider-element,
.massive-progress.progress-theme-color .progress-bar > span,
.massive-progress.progress-theme-color .progress-bar > span:before

{
    background: $theme-color-default !important;
}

.massive-progress.progress-theme-color .progress-bar > span:before {
    border-color: $theme-color-default;
}

.btn-theme-border-color,
.widget-social-link a:hover,
.widget-tags a:hover {
    border:1px solid $theme-color-default ;
}

.icon-tabs .nav-pills>li>a:hover,
.icon-tabs .nav-pills>li.active>a,
.icon-tabs .nav-pills>li.active>a:focus,
.icon-tabs .nav-pills>li.active>a:hover {
    border-bottom:1px solid $theme-color-default;
}

.btn-theme-border-color:hover,
.price-col.feature .p-btn:hover,
.side-social-link a:hover,
.massive-progress.progress-theme-color .progress-bar > span:before{
    border:1px solid $theme-color-default;
}

.accordion.time-line > dt > a:hover:after,
.accordion.time-line > dt > a.active:after,
.accordion.time-line > dt > a.active:hover:after,
.toggle.time-line > dt > a:hover:after,
.toggle.time-line > dt > a.active:after,
.toggle.time-line > dt > a.active:hover:after,
.btn-dark-solid:hover,
.btn-dark-solid:focus,
.btn-theme-color ,
.btn-theme-border-color:hover,
.btn-light-solid:hover,
.feature-border-box:hover .icon,
.price-col.feature .p-btn:hover,
.side-social-link a:hover,
.widget-social-link a:hover,
.widget-tags a:hover,
.sale-label,
.stock .status,
.lift-off:hover,
.menuzord-menu ul.dropdown li:hover>a,
.menuzord-menu>li>.megamenu .megamenu-row li:hover a,
.travel-form.icon-tabs .nav-pills>li>a:hover,
.travel-form.icon-tabs .nav-pills>li.active:hover>a,
.travel-form.icon-tabs .nav-pills>li.active>a,
.travel-form.icon-tabs .nav-pills>li.active>a:focus {
    background: $theme-color-default;
}

.menuzord .menuzord-menu ul.dropdown li:hover>a,
.menuzord-menu>li>.megamenu .megamenu-row li:hover>a {
    color: #fff;
}

@media (min-width: 768px) {
    .c-nav_s-underline.menuzord-menu>li.active>a,
    .c-nav_s-underline.menuzord-menu>li:hover>a {
        border-bottom:1px solid $theme-color-default;
    }

    .c-nav_s-outline.menuzord-menu > li.active > a,
    .c-nav_s-outline.menuzord-menu > li:hover > a {
        border:1px solid $theme-color-default;
    }

    .c-nav_s-bg.menuzord-menu > li.active > a,
    .c-nav_s-bg.menuzord-menu > li:hover > a {
        background: $theme-color-default;
    }
}