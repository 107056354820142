/*------------------------------------------------------------------
    carousel
-------------------------------------------------------------------*/

#img-carousel .item {
    margin: 5px;
}
#img-carousel .item img {
    display: block;
    width: 100%;
    height: auto;
}

#portfolio-carousel .portfolio-item img,
#portfolio-carousel-alt .portfolio-item img {
    display: block;
    width: 100%;
    height: auto;
}

#portfolio-carousel.portfolio.col-3 .portfolio-item,
#portfolio-carousel-alt.portfolio.col-3 .portfolio-item{
    width: 100%;
}

#portfolio-carousel.portfolio .portfolio-item,
#portfolio-carousel-alt.portfolio .portfolio-item{
    padding: 5px;
}

.owl-theme .owl-controls .owl-page span {
    background: #eaeaea !important;
}

#portfolio-carousel.owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    top: -100px;
    right: 0;
}


#portfolio-carousel-alt.owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 86px;
    left: -100px;
}

#portfolio-carousel.owl-theme .owl-controls .owl-buttons div,
#portfolio-carousel-alt.owl-theme .owl-controls .owl-buttons div{
    border-radius: 0;
    background: #E5E5E5;
    padding: 8px 16px;
}

#portfolio-carousel.owl-theme .owl-controls .owl-buttons div i,
#portfolio-carousel-alt.owl-theme .owl-controls .owl-buttons div i{
    color: #000;
    font-weight: bold;
}

#portfolio-carousel-alt.portfolio {
    margin:0;
}

/**/

.c-info-row {

}

.c-info-row .c-info {
    width: 30%;
}

.c-info-row .c-info {
    padding-right: 30px;
}

.c-info-row .c-slide {
    width: 70%;
}

.c-info-row .c-info,
.c-info-row .c-slide {
    float: left;
}

/**/

.slides li,
.carousel.slide .carousel-inner {
    position: relative;
}

.slides .caption,
.carousel.slide .carousel-inner .caption {
    position: absolute;
    bottom: 50px;
    left: 0;
    background: rgba(0,0,0,0.5);
    padding: 15px 20px;
    color: #fff;
    font-size: 24px;
}


/*---arrow carousel---*/

.full-width img {
    width: 100%;
    height: auto;
}

.carousel.slide .carousel-control {
    width: 50%;
    z-index: 90;
}

.carousel.slide  a.right.carousel-control:hover {
    cursor: url(../img/carousel/arrow_right.png), auto;
}

.carousel.slide  a.left.carousel-control:hover {
    cursor: url(../img/carousel/arrow_left.png), auto;
}

.carousel.slide  a.left.carousel-control,
.carousel.slide  a.right.carousel-control{
    background-image: none;
}

.carousel.slide  .carousel-fade .carousel-control {
    z-index: 5000;
}

.carousel.slide  .carousel-control:hover,
.carousel.slide .carousel-control:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}



/**/


.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 5000;
}


@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


/**/

.side-object .object {
    width: 30%;
    margin-top: 100px;
    position: relative;
    height: 400px;
}

.side-object .object img{
    position: absolute;
    left: 0;
    bottom: 0;
}

.side-object .content {
    width: 70%;
    text-align: left;
    padding: 200px 0 0 100px;
}
.side-object .object,
.side-object .content {
    float: left;
}


#carousel-object .owl-controls {
    position: absolute;
    bottom: 30px;
    right: 0;
}

/*----------*/

.slider-full-screen,
.slider-full-width,
.slider-boxed .container{
    position: relative;
}

.slider-full-screen img {
    width: 100%;
    height: 100vh;
}

.slider-full-width img,
.slider-boxed img{
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
}


.slider-boxed {
    margin-top: 50px;
}

.slider-boxed .container {
    padding: 0;
}


/*---------------*/

#owl-slider .owl-item div{
    padding:0px;
}

#owl-slider .owl-item img{
    display: block;
    width: 100%;
    height: auto;
}
#owl-slider .owl-pagination{
    bottom: 50px;
    position: relative;
}


#owl-slider.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 50px;
    height: 5px;
    margin: 5px 7px;
    filter: Alpha(Opacity=50);
    opacity: 0.5;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0;
}

#owl-slider.owl-theme .owl-controls .owl-page.active span {
    filter: Alpha(Opacity=100);
    opacity: 1;
    z-index: 1200;
}

#owl-slider .owl-buttons {
    position: absolute;
    right: 30px;
    top:50% ;
    margin-top: -70px;
}

#owl-slider.owl-theme .owl-controls .owl-buttons div {
    color: #FFF;
    display: block;
    zoom: 1;
    margin: 5px;
    padding: 5px 15px;
    font-size: 20px;
    border-radius: 0;
    background: #000;
    filter: Alpha(Opacity=50);
    opacity: 0.5;
}

#owl-slider.owl-theme .owl-controls .owl-buttons div:hover {
    opacity: 1;
}