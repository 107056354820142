.slides {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {
  outline: none;
  border: none;
}

.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}

.flex-direction-nav {
  display: none;
}

.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-nav {
  position: absolute;
  z-index: 10;
  text-align: center;
  bottom: 15px;
  right: 15px;
  margin: 0;
}

.flex-control-nav li {
  float: left;
  display: block;
  margin: 0 3px;
  width: 10px;
  height: 10px;
}

.flex-control-nav li a {
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
  background-color: #fff;
}

.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -40px 0 0;
  position: absolute;
  top: 50%;
  z-index: 200;
  overflow: hidden;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  line-height: 40px;
}

.flex-direction-nav a:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.flex-direction-nav a:before {
  font-family: "fontawesome";
  font-size: 16px;
  display: block;
  content: '\f104';
  color: #fff;
  text-align: center;
  width: 40px;
}

.flex-direction-nav a.flex-next:before {
  content: '\f105';
}

.flex-direction-nav .flex-prev {
  left: 0px;
}

.flex-direction-nav .flex-next {
  right: 0px;
  text-align: right;
}

.post-slider-thumb .flex-control-thumbs {
  position: relative;
  z-index: 10;
  text-align: left;
  top: 10px;
  right: auto;
  margin: 0 auto;
  left: auto;
}

.post-slider-thumb .flex-control-thumbs li {
  float: none;
  display: inline-block;
  margin: 0 3px 0 0;
  width: 80px;
  height: auto;
  cursor: pointer;
}

.post-slider  .flex-direction-nav,
.post-slider-thumb  .flex-direction-nav {
  display: block;
}

.post-slider .flex-direction-nav .flex-prev,
.post-slider .flex-direction-nav .flex-next,
.post-slider-thumb .flex-direction-nav .flex-prev,
.post-slider-thumb .flex-direction-nav .flex-next
{
  opacity: 0;
}

.post-slider:hover .flex-direction-nav .flex-prev,
.post-slider:hover .flex-direction-nav .flex-next,
.post-slider-thumb:hover .flex-direction-nav .flex-prev,
.post-slider-thumb:hover .flex-direction-nav .flex-next {
  opacity: 1;
}

.post-list-aside .post-img img  {
  border: 2px solid transparent;
}

.post-list-aside .post-img img.flex-active {
  border: 2px solid rgba(0, 0, 0, 0.8);
}

.post-slider .flex-control-thumbs{
  display: none;
}

@media screen and (max-width: 860px) {

  .flex-direction-nav .flex-prev,
  .post-slider .flex-direction-nav .flex-prev,
  .post-slider-thumb .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 0px;
  }
  .flex-direction-nav .flex-next,
  .post-slider .flex-direction-nav .flex-next,
  .post-slider-thumb .flex-direction-nav .flex-next{
    opacity: 1;
    right: 0px;
  }
}
