@import 'overwrite/accessibility';

@import 'overwrite/columns';

@import 'overwrite/forms';

// Variables
$pictonblue: #3fafe5;
$pictonblue2: #51b3e3;
$pictonblue3: #249edb;
$pictonblue4: #1d7eaf;
$skyblue: #6dc2eb;
$porsche: #eea673;
$heavymetal: #282a29;
$capecod: #393f3e;
$capecod2: #414747;
$scorpion: #595959;
$blackhaze: #e5e6e6;

// Global
body {
  color: $heavymetal;
}

// Images
img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

// Typography
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px 0;
}

p,
ul,
ol {
  margin: 0 0 15px 0;
  color: $scorpion;
  font-size: 18px;
  line-height: 28px;
}

.btn.btn-dark-solid,
.btn-primary,
a.btn.btn-dark-solid,
a.btn-primary,
.subpage .page-content a.btn.btn-dark-solid,
.subpage .page-content a.btn-primary {
  background: $porsche;
  color: $heavymetal;
  text-decoration: none;

  &:visited {
    background: $porsche;
    color: $heavymetal;
  }

  &:hover,
  &:focus {
    background: $pictonblue;
    color: $heavymetal;
  }
}

// Landing Page
.full-banner,
.full-banner .banner-23 {
  background-image: none;
}

// Header
.l-navbar-wrapper {
  height: 100px !important;

  @media screen and (max-width: 768px) {
    height: 50px !important;
  }

  // &.l-navbar-wrapper_has-sticky {
  //   height: 60px !important;
  // }

  // Logo
  .logo-brand {
    position: relative;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 280px;
      height: 100px;
      background-image: url('../images/logo-header.svg');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (max-width: 768px) {
        width: 170px;
        height: 50px;
      }
    }
  }

  &.l-navbar-wrapper_has-sticky .logo-brand .logo {
    width: 170px;
    height: 50px;
  }
}

// Banner
.banner-wrap {
  position: relative;
}

.full-banner {
  background: gray;

  .banner {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .text {
    display: inline-block;
    background: rgba($capecod2, 0.6);
    color: white;

    @media screen and (min-width: 769px) {
      padding: 10px 24px;
      max-width: 1045px;
      font-weight: 300;
      font-size: 72px;
      line-height: 66px;
    }

    &.yes {
      display: none;
    }
  }
}

.slider-nav {
  position: absolute;

// top: 20%;
  bottom: 40px;
  z-index: 102;
  padding-top: 30px;

// transform: translateY(-50%);
  width: 40px;
  height: 100px;
  background: rgba(black, 0.35);
  color: white;
  text-align: center;

  &:before {
    position: relative;
    color: rgba(white, 0.65);
    text-decoration: inherit;
    font-weight: normal;
    font-style: normal;
    font-size: 30px;
    font-family: FontAwesome;
  }

  &.slider-prev {
    left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &:before {
      left: -2px;
      content: '\f053';
    }
  }

  &.slider-next {
    right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &:before {
      right: -2px;
      content: '\f054';
    }
  }
}

// Menu
.menuzord .showhide em {
  color: $heavymetal;
}

.menuzord-menu > li > a {
  color: $heavymetal;

  &:visited {
    color: $heavymetal;
  }

  &:hover,
  &:focus {
    color: $pictonblue4;
  }
}

@media (min-width: 769px) {
  .menuzord-menu ul.dropdown {
    border-top: 5px solid $pictonblue !important;
  }
}

.menuzord-menu ul.dropdown li a {
  color: $heavymetal;

  &:visited {
    color: $heavymetal;
  }
}

.menuzord .menuzord-menu ul.dropdown li:hover > a,
.menuzord-menu ul.dropdown li:hover > a {
  background: $skyblue;
  color: $heavymetal;
}

.menuzord-menu ul.dropdown li ul.dropdown {
  right: 100%;
  left: auto;
}

@media (min-width: 769px) {
  .l-header_overlay {
    background: white;
  }
}

// Homepage Styles
.homepage {
  h2 {
    color: $pictonblue3;
    text-transform: uppercase;
    letter-spacing: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }

  .post-list-aside {
    margin-bottom: 0;
  }

  .post-desk {
    padding-bottom: 0;
  }

  .callouts {
    display: block;
    background: $blackhaze;

    h2 {
      margin: 24px 0 0 0;
      color: $capecod;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;

      a {
        color: $capecod;

        &:visited {
          color: $heavymetal;
        }

        &:hover,
        &:focus {
          color: $pictonblue;
        }
      }
    }
  }
}

// Subpage Styles
.subpage {
  .l-header {
    border-bottom: 5px solid $pictonblue;
  }

  @media (min-width: 769px) {
    .l-navbar_t-light {
      border-bottom: 5px solid $pictonblue;
    }
  }

  .page-content {
    h1 {
      margin: 0 0 15px 0;
      color: $pictonblue3;
      text-transform: uppercase;
      letter-spacing: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
    }

    a {
      color: $pictonblue;
      text-decoration: underline;

      &:visited {
        color: $pictonblue;
      }

      &:hover,
      &:focus {
        color: $heavymetal;
      }
    }
  }
}

// Team
.team-title {
  h3 {
    margin-bottom: 10px;
  }

  .title {
    display: block;
    margin-bottom: 10px;
  }

  span {
    color: #757575 !important;
  }
}

.testimonial {
  .item {
    margin: 20px;
    text-align: center;
  }

  .icon {
    display: inline-block;
    margin-bottom: 30px;
    font-size: 30px;
  }

  p {
    color: #757575 !important;
    font-style: italic;
    font-size: 16px;
    line-height: 30px;
  }
}

.testimonial-meta span {
  color: #757575;
}

// Related Pages/Services
.related {
  .heading-title {
    margin-bottom: 20px;
  }

  .feature-bg-box {
    margin-bottom: 30px;
  }
}

.heading-title-alt span {
  color: #757575;
}

.featured-item .desc {
  color: #707070;
}

.featured-item .title h3 {
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: normal;
  font-size: 18px;
}

.page-title.parallax-title {
  background-image: url('../images/banner.jpg');
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.page-title .breadcrumb {
  padding: 0 5px;
  background-color: rgba(white, 0.65);

   > li + li:before {
    color: #7e7e7e;
  }
}

.pagination {
  display: block;

  a,
  span {
    position: relative;

// float: left;
    display: inline-block;
    margin: 0 3px;
    padding: 6px 12px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    color: #424242;
    line-height: 1.42857143;
  }

  span {
    z-index: 2;
    border-color: #222222;
    background-color: #222222;
    color: #ffffff;
    cursor: default;
  }
}

.pagination-post .post-list-link {
  float: none;
  width: auto;
}

.post-meta li {
  color: #757575 !important;
}

.blog-classic.news-detail {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.blog-classic .blog-post {
  h2 {
    a {
      color: $pictonblue3;
    }
  }

  @media only screen and (max-width: 375px) {
    overflow: visible;
  }
}

// Forms
.content-form {
  label {
    display: block;
    font-weight: 400;
  }

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  textarea,
  select {
    display: block;
    padding: 6px 12px;
    width: 100%;
    border: 0;
    border-radius: 4px;
    background: #f5f5f5;
    color: #555555;
    font-size: 14px;
    line-height: 1.42857143;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  input[type='submit'] {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 0;
    background: #eea673;
    color: #282a29;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1px;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.42857143;
    transition: all 0.3s;

    &:hover,
    &:focus {
      background: #3fafe5;
    }
  }
}

// Accordions
.subpage .page-content .toggle {
   > dt > a {
    border: 0;
    background: $porsche;
    color: $heavymetal;
    text-decoration: none;

    &:after {
      color: $heavymetal;
    }

    &:visited {
      background: $porsche;
      color: $heavymetal;

      &:after {
        color: $heavymetal;
      }
    }

    &:hover,
    &:focus {
      background: $pictonblue;
      color: $heavymetal;

      &:after {
        color: $heavymetal;
      }
    }

    &.active {
      background: $pictonblue;
      color: $heavymetal;

      &:after {
        color: $heavymetal;
      }

      &:hover,
      &:focus {
        background: $pictonblue;
        color: $heavymetal;

        &:after {
          color: $heavymetal;
        }
      }
    }
  }

   > dd {
    padding: 0;
  }
}

// Footer {
#footer.dark {
  border-top: 5px solid $pictonblue;
  background: $capecod;
  color: #a7aaaa;
}
