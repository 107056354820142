.video-fit  {
  width: 100%;
  padding-top: 50%;
  position: relative;
}

.audio-fit {
  width: 100%;
  padding-top: 20%;
  position: relative;
}

.video-fit iframe,
.video-fit object,
.video-fit embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.audio-fit iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 100%;*/
  border: none;
}