#footer.gray {
    background: #f5f5f5;
    padding: 80px 0;
}

#footer.dark {
    background: #222;
}

#footer.gray  .sub-title {
    font-size: 12px;
    color: #222;
    letter-spacing: 2px;
    margin: 15px 0;
}

#footer.dark  .sub-title {
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 2px;
    margin: 15px 0;
}

#footer.gray  .social-link  a {
    display: inline-block;
    font-size: 20px;
    color: #222;
    margin: 10px;
}

#footer.dark  .social-link  a {
    display: inline-block;
    font-size: 20px;
    color: #ffffff;
    margin: 5px;
}

#footer.gray  .social-link.circle a {
    border: 1px solid #222;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
}

#footer.dark  .social-link.circle a {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
}

#footer.gray  .social-link.circle a:hover {
    background: #222;
    color: #fff;
    border: 1px solid transparent;
}

#footer.dark  .social-link.circle a:hover {
    background: #fff;
    color: #222;
    border: 1px solid transparent;
}

#footer.gray  .copyright {
    font-size: 14px;
    color: #000;
    letter-spacing: 2px;
}

#footer.dark  .copyright {
    font-size: 14px;
    color: #fff;
    letter-spacing: 2px;
}

#footer.gray  .copyright-sub-title {
    font-size: 12px;
    letter-spacing: 2px;
}

.footer-logo {
    display: block;
}

// Footer 2
.f-link,
#footer.dark h5 {
    color: #fff;
}

#footer.dark h5 {
    letter-spacing: 1px;
}

#footer.dark {
    color: #6f7273;
}

#footer.dark .primary-footer {
    background: #222222;
    padding: 80px 0;
}

#footer .f-list, .r-work {
    padding: 0;
    margin: 0;
    list-style: none;
}

#footer .f-list li a {
    line-height: 45px;
    color: #a2a2a2;
    display: block;
}

#footer .f-list li  {
    border-bottom: 1px solid #333333;
}

#footer .f-list li a:before {
    content:"\f105";
    font-family: 'fontawesome';
    padding-right: 15px;
}

.r-work li a {
    float: left;
    margin: 0 10px 10px 0;
}

.r-work li a {
    width: 55px;
    height: 55px;
}

.r-work li a img{
    width: 100%;
    height: auto;
}

#footer.dark .secondary-footer {
    background: #1b1b1b;
    padding: 35px 0;
    border-top:1px solid #2c2c2c ;
    display: inline-block;
    width: 100%;
    font-weight: normal;
}

#footer.dark .secondary-footer .social-link  a {
    display: inline-block;
    font-size: 20px;
    color: #5a5a5a;
    margin:0 0 0 15px;
}

#footer.dark .secondary-footer .social-link.circle  a{
    border: 1px solid #5a5a5a;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
}

#footer.dark .secondary-footer .social-link.circle  a:hover{
    background: #5a5a5a;
    color: #fff;
    border: 1px solid transparent;
}

// Footer 3
.footer-3,
.footer-4 {
    position: relative;
}

.footer-3 .f-logo-center {
    text-align: center;
    position: absolute;
    top: -50px;
    background-color: #fff;
    padding: 20px;
    left: 50%;
    margin-left: -3%;
    border: 5px solid #f5f5f5;
}

.footer-4 .f-logo-center {
    text-align: center;
    position: absolute;
    top: -50px;
    background-color: #fff;
    padding: 20px;
    left: 50%;
    margin-left: -5%;
    border: 5px solid #222;
}

.footer-4.dark {
    background: #222222;
    padding: 80px 0;
}

.footer-4.dark .social-link  a {
    display: inline-block;
    font-size: 20px;
    color: #fff;
    margin: 10px;
}

.footer-4.dark  .social-link.circle a {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
}

.footer-4.dark  .social-link.circle a:hover {
    background: #fff;
    color: #222;
    border: 1px solid transparent;
}

.footer-4.dark  .copyright {
    font-size: 14px;
    color: #fff;
    letter-spacing: 2px;
}

.footer-4.dark  .copyright-sub-title {
    font-size: 12px;
    letter-spacing: 2px;
    color: #d0d0d0;
}

.gray .border-top {
    border-top:1px dashed rgba(0,0,0,0.1);
    padding-top: 40px;
}

.dark .border-top {
    border-top:1px dashed rgba(255,255,255,0.2);
    padding-top: 40px;
}

.short-pad {
    padding: 40px 0 !important;
}