.menuzord {
  width: 100%;
  float: left;
  position: relative;
  text-transform: uppercase;
  letter-spacing: .5px;
  background: none;
  padding: 0;
}

.logo-brand {
  float: left;
  height: 50px;
  margin-right: 30px;
  font-size: 20px;
  line-height: 50px;
  font-weight: 600;
  color: #666;
}

.logo-brand img {
  position: relative;
  top: -2px;
  display: inline-block;
  max-height: 100%;
  padding: 2px 0;
}

.menuzord-menu {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none
}

.menuzord-menu.menuzord-right {
  float: right
}

.menuzord-menu ul.dropdown li a,
.menuzord-menu > li > .megamenu a,
.menuzord-menu > li > .megamenu h5 {
  color: #222;
}

.menuzord-menu > li > .megamenu h5 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  padding-left: 10px;
}

.menuzord-menu > li > .megamenu .megamenu-row li a {
  padding: 8px 10px;
  width: 100%;
  display: inline-block;
  font-size: 12px;
}

.menuzord-menu a {
  transition: color .3s, background .3s;
}

.menuzord-menu.scrollable>.scrollable-fix {
  display: none
}

.menuzord-menu > li {
  display: inline-block;
  float: left;
  margin-left: 2px;
}

.menuzord-menu > li > a {
  display: inline-block;
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  padding: 0 12px;
  color: #333;
}

.menuzord-menu > li.active > a,
.menuzord-menu > li:hover > a {
  color: #333
}

.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 1000;
  min-width: 190px;
  white-space: nowrap;
  background: #fff
}

.menuzord-menu ul.dropdown li ul.dropdown {
  left: 100%;
  top:-1px;
}

.menuzord-menu ul.dropdown li {
  clear: both;
  width: 100%;
  border: 0;
  font-size: 12px;
  position: relative
}

.menuzord-menu ul.dropdown li a {
  width: 100%;
  background: 0 0;
  padding: 10px 25px 10px 22px;
  color: #222;
  text-decoration: none;
  display: inline-block;
  float: left;
  clear: both;
  position: relative;
  outline: 0;
}

.menuzord-menu ul.dropdown li:hover > a {
  padding-right: 19px;
  color: #333;
}

.menuzord-menu ul.dropdown.dropdown-left {
  left: auto;
}

.menuzord-menu ul.dropdown li ul.dropdown.dropdown-left {
  left: auto;
  right: 100%;
}

.menuzord-menu li .indicator {
  margin-left: 3px;
}

.menuzord-menu li ul.dropdown li .indicator {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 15px
}

.menuzord-menu > li > .megamenu {
  width: 100%;
  padding: 20px;
  left: 0;
  position: absolute;
  display: none;
  z-index: 1000;
  border-top: solid 1px #f0f0f0;
  background: #fff
}

.menuzord-menu > li > .megamenu.megamenu-half-width {
  width: 50%;
  left: auto
}

.menuzord-menu > li > .megamenu.megamenu-quarter-width {
  width: 25%;
  left: auto
}

.menuzord-menu > li > .megamenu .megamenu-row {
  width: 100%;
  margin-top: 15px
}

.menuzord-menu > li > .megamenu .megamenu-row:first-child {
  margin-top: 0
}

.menuzord-menu > li > .megamenu .megamenu-row:after,
.menuzord-menu > li > .megamenu .megamenu-row:before {
  display: table;
  content: "";
  line-height: 0
}

.menuzord-menu>li>.megamenu .megamenu-row:after {
  clear: both
}

.menuzord-menu>li>.megamenu .megamenu-row .col1,
.menuzord-menu>li>.megamenu .megamenu-row .col10,
.menuzord-menu>li>.megamenu .megamenu-row .col11,
.menuzord-menu>li>.megamenu .megamenu-row .col12,
.menuzord-menu>li>.megamenu .megamenu-row .col2,
.menuzord-menu>li>.megamenu .megamenu-row .col3,
.menuzord-menu>li>.megamenu .megamenu-row .col4,
.menuzord-menu>li>.megamenu .megamenu-row .col5,
.menuzord-menu>li>.megamenu .megamenu-row .col6,
.menuzord-menu>li>.megamenu .megamenu-row .col7,
.menuzord-menu>li>.megamenu .megamenu-row .col8,
.menuzord-menu>li>.megamenu .megamenu-row .col9 {
  display: block;
  min-height: 20px;
  float: left;
  margin-left: 3%
}

.menuzord-menu>li>.megamenu .megamenu-row [class*=col]:first-child {
  margin-left: 0
}

.menuzord-menu>li>.megamenu .megamenu-row .col1 {
  width: 5.583333333333%
}

.menuzord-menu>li>.megamenu .megamenu-row .col2 {
  width: 14.166666666666%
}

.menuzord-menu>li>.megamenu .megamenu-row .col3 {
  width: 22.75%
}

.menuzord-menu>li>.megamenu .megamenu-row .col4 {
  width: 31.333333333333%
}

.menuzord-menu>li>.megamenu .megamenu-row .col5 {
  width: 39.916666666667%
}

.menuzord-menu>li>.megamenu .megamenu-row .col6 {
  width: 48.5%
}

.menuzord-menu>li>.megamenu .megamenu-row .col7 {
  width: 57.083333333333%
}

.menuzord-menu>li>.megamenu .megamenu-row .col8 {
  width: 65.666666666667%
}

.menuzord-menu>li>.megamenu .megamenu-row .col9 {
  width: 74.25%
}

.menuzord-menu>li>.megamenu .megamenu-row .col10 {
  width: 82.833333333334%
}

.menuzord-menu>li>.megamenu .megamenu-row .col11 {
  width: 91.416666666667%
}

.menuzord-menu>li>.megamenu .megamenu-row .col12 {
  width: 100%
}

.menuzord .showhide {
  width: 30px;
  height: 46px;
  padding: 13px 0 0;
  display: none;
  float: right;
  text-decoration: none;
  outline: 0;
  -webkit-tap-highlight-color: transparent
}

.menuzord .showhide em {
  width: 20px;
  height: 3px;
  margin: 3.5px 0px 0;
  float: right;
  background: #777
}

.menuzord-menu > li > a > .fa {
  margin: 0 5px 0 0;
  font-size: 14px;
  float: left;
  line-height: inherit;
  color: inherit;
}

.menuzord-menu>li.search form {
  float: left;
  padding: 22px 16px 17px;
}

.menuzord-tabs {
  width: 100%;
  float: left
}

.menuzord-tabs-nav {
  width: 20%;
  margin: 0;
  padding: 0;
  float: left;
  list-style: none
}

.menuzord-tabs-nav>li>a {
  width: 100%;
  padding: 7px 16px;
  float: left;
  font-size: 13px;
  text-decoration: none;
  color: #666;
  border: 1px solid #f0f0f0;
  outline: 0
}

.menuzord-tabs-nav li.active a,
.menuzord-tabs-nav li:hover a {
  background: #f0f0f0
}

.menuzord-tabs-content {
  width: 80%;
  min-height: 30px;
  padding: 20px;
  float: right;
  display: none;
  font-size: 13px;
  border: 1px solid #f0f0f0
}

.menuzord-tabs-content.active {
  display: block
}

.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown{
  background: #fff;
  border: 1px solid #efefef;
}

.menuzord-menu ul.dropdown li:hover > a{
  color: #fff;
}

.menuzord-menu > li > .megamenu {
  border-top: none;
  background: #fff;
  border: 1px solid #efefef;
}

.menuzord-tabs-nav > li > a {
  color: #fff;
}

.navbar-search .form-control {
  border-radius: 0;
}


// New header and navbar
@media (max-width: 768px) {
  .wrapper {
    // margin-top: 50px;
  }

  .l-header {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 1000;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0,0,0,.1);
  }

  .menuzord-responsive .menuzord-menu > li.nav-divider {
    display: none;
  }

  .l-navbar_t-light,
  .l-navbar_t-light-trans,
  .l-navbar_t-dark .showhide em,
  .l-navbar_t-semi-trans .showhide em,
  .l-navbar_t-dark-trans .showhide em {
    background: #fff;
  }

  .l-navbar_t-dark,
  .l-navbar_t-semi-trans,
  .l-navbar_t-dark-trans {
    background: #0a0a0a;
  }
}

@media (min-width: 769px) {
  .l-header_overlay {
    position: absolute;
    z-index: 1200;
    top: 0;
    width: 100%;
  }

  .menuzord-menu .megamenu,
  .menuzord-menu .dropdown {
    text-align: left;
    border: 0 !important;
  }

  .menuzord-menu .megamenu,
  .menuzord-menu .dropdown,
  .l-navbar-wrapper_has-sticky .l-navbar,
  .l-navbar_s-left {
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
  }

  .logo-brand {
    height: 100px;
    display: inline-block;
  }

  .l-navbar-wrapper_has-sticky .logo-brand {
    display: block;
  }

  .logo-brand,
  .c-nav_s-standard > li > a {
    line-height: 100px;
  }

  .c-nav_s-bg > li > a {
    line-height: 40px;
  }

  .c-nav_s-bg > li.active > a,
  .c-nav_s-bg > li:hover > a {
    color: #fff !important;
  }

  .c-nav_s-outline > li > a {
    border: 1px solid transparent;
    line-height: 38px;
  }

  .c-nav_s-underline > li > a {
    line-height: 99px;
    border-bottom: 1px solid transparent;
  }

  .l-navbar_expand .c-nav_s-bg > li > a,
  .l-navbar_expand .c-nav_s-outline > li > a {
    margin: 30px 0;
  }

  .l-navbar_compact .c-nav_s-bg > li > a,
  .l-navbar_compact .c-nav_s-outline > li > a {
    margin: 10px 0;
  }

  .l-navbar-wrapper_has-sticky .c-nav_s-bg > li > a,
  .l-navbar-wrapper_has-sticky .c-nav_s-outline > li > a {
    margin: 0;
  }

  .l-navbar-wrapper_has-sticky .c-nav_s-outline > li > a {
    line-height: 58px;
  }

  .menuzord-menu .dropdown > li > a,
  .menuzord-menu .megamenu-row li > a {
    line-height: 18px;
  }

  .l-navbar-wrapper,
  .logo-brand,
  .menuzord-menu > li > a {
    transition: height .3s, line-height .3s, margin .3s;
  }

  .l-navbar_t-light {
    background: #fff;
  }

  .l-navbar_t-dark {
    background: rgba(10,10,10, 1);
  }

  .l-navbar_t-dark .menuzord-menu > li > a,
  .l-navbar_t-semi-trans .menuzord-menu > li > a,
  .l-navbar_t-dark-trans .menuzord-menu > li > a {
    color: #fff;
  }

  .l-navbar_t-semi-trans {
    background: rgba(10,10,10,.5);
  }

  .l-navbar_t-light-trans,
  .l-navbar_t-dark-trans,
  .l-navbar_t-semi-trans {
    transition: background-color .3s;
  }

  .l-navbar_compact .logo-brand {
    height: 60px;
  }

  .l-navbar_compact .logo-brand,
  .l-navbar_compact .c-nav_s-standard > li > a {
    line-height: 60px;
  }

  .l-navbar_compact.l-navbar_s-center .logo-brand {
    height: 100px;
  }

  .l-navbar_s-center {
    text-align: center;
  }

  .l-navbar_s-center.l-navbar_t-light {
    border-top-color: #ddd;
  }

  .l-navbar_s-center.l-navbar_t-light-trans .menuzord-menu,
  .l-navbar_s-center.l-navbar_t-dark-trans .menuzord-menu,
  .l-navbar_s-center.l-navbar_t-semi-trans .menuzord-menu {
    border-top-color: rgba(10,10,10,.2);
  }

  .l-navbar_s-center.l-navbar_t-dark {
    border-top-color: #222;
  }

  .l-navbar_s-center .logo-brand {
    float: none;
  }

  .l-navbar_s-center .menuzord-menu,
  .l-navbar_s-center .menuzord-menu > li {
    float: none;
    text-align: center;
  }

  .l-navbar_s-center .logo-brand.sticky-fix {
    float: left;
  }

  .l-navbar-wrapper_has-sticky .l-navbar_s-center .menuzord-menu.menuzord-right,
  .l-navbar-wrapper_has-sticky .l-navbar_s-center .menuzord-menu.pull-right {
    float: right;
  }

  .l-navbar_s-floating {
    width: 1170px;
    margin: auto;
    margin-top: 50px;
  }

  .l-navbar-wrapper_has-sticky .l-navbar_s-floating {
    margin-top: 0;
  }

  .l-navbar-wrapper_has-sticky .l-navbar_t-semi-trans,
  .l-navbar-wrapper_has-sticky .l-navbar_t-dark-trans {
    background: #0a0a0a;
  }

  .l-navbar-wrapper_has-sticky .l-navbar_t-light-trans {
    background: #fff;
  }

  .l-navbar-wrapper_has-sticky .logo-brand,
  .l-navbar-wrapper_has-sticky .menuzord-menu > li > a {
    line-height: 60px;
  }

  .l-navbar-wrapper_has-sticky .logo-brand {
    height: 60px;
  }

  .body_has-navbar-left .wrapper {
    margin-left: 260px;
  }

  .body_has-navbar-left .l-header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    width: 260px;
  }

  .body_has-navbar-left .l-navbar_s-left {
    height: 100%;
  }

  .l-navbar_s-left.l-navbar_t-dark {
    background: #333;
  }

  .l-navbar_s-left .container,
  .l-navbar_s-left .container-fluid {
    width: auto;
    padding: 0;
  }

  .l-navbar_s-left .logo-brand,
  .l-navbar_s-left .menuzord-menu,
  .l-navbar_s-left .menuzord-menu > li {
    float: none;
  }

  .l-navbar_s-left .logo-brand {
    display: block;
    margin: 40px 20px;
    text-align: center;
  }

  .l-navbar_s-left .menuzord-menu > li {
    display: block;
    margin: 0;
  }

  .l-navbar_s-left .menuzord-menu > li > a {
    display: block;
    padding: 0 20px;
  }

  .l-navbar_s-left .menuzord-menu > li > a {
    line-height: 40px;
  }

  .l-navbar_s-left .menuzord-menu > li > .dropdown,
  .l-navbar_s-left .menuzord-menu > li > .megamenu {
    left: 260px !important;
    margin-top: -40px;
  }

  .l-navbar_s-left .menuzord-menu > li > .megamenu {
    width: 800px;
    padding: 20px;
  }

  .l-navbar_s-left .menuzord-menu > li.nav-divider {
    display: none;
  }

  .l-navbar_s-left .menuzord-menu > li > .navbar-search {
    width: 450px;
  }

  .nav-divider a {
    cursor: text;
  }

  .l-navbar_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
