/*------------------------------------------------------------------
    team member
-------------------------------------------------------------------*/

.team-member {
    overflow: hidden;
}

.team-member ,
.team-member .team-img {
    position: relative;
}

.team-member .team-img img{
    width: 100%;
    height: auto;
}

.team-member .team-intro {
    position: absolute;
    right: 0;
    bottom: 30px;
    background: rgba(0, 0, 0, .7);
    padding: 10px 20px;
    width: 70%;
    text-align: right;
}

.team-member .team-intro h5,
.team-member .team-intro.light-txt span {
    margin-bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.team-member .team-intro.light-txt h5{
    color: #fff;
}

.team-member .team-intro.light-txt span {
    color: rgba(255, 255, 255, .5);
    font-size: 12px;
}


.team-member .s-link a{
    margin: 0 10px;
    color: #333;
    font-size: 16px;
}

.t-s-link{
    margin-top: 10px;
    display: block;
}

.t-s-link a {
    margin-right: 10px;
    color: #333;
    font-size: 16px;
}

/**/


.team-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    border: 20px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.90);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}


.team-member:hover .team-hover,
.team-member:hover .team-hover .desk,
.team-member:hover .team-hover .s-link {
    opacity: 1;
}


.team-member:hover .team-hover .s-link {
    bottom: 10%;
}

.team-member:hover .team-hover .desk {
    top: 35%;
}

.team-hover .desk {
    position: absolute;
    top: 0%;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    padding: 0 20px;
}


.team-hover .s-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}

.desk,
.desk h4,
.team-hover .s-link a {
    text-align: center;
    color: #222;
}

.desk h4 {
    margin-bottom: 25px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.desk p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: normal;
    color: #7e7e7e;
    line-height: 30px;
}

.team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 100%;
}

.team-title h5 {
    margin-bottom:0px ;
    display: block;
    text-transform: uppercase;
}

.team-title span {
    font-size: 12px;
    text-transform: uppercase;
    color: #a5a5a5;
    letter-spacing: 1px;
}

.team-title p {
    padding-top: 30px;
    letter-spacing: normal;
    line-height: 30px;
}

