/*------------------------------------------------------------------
    timeline
-------------------------------------------------------------------*/

.timeline {
    border-collapse:collapse;
    border-spacing:0;
    display:table;
    position:relative;
    table-layout:fixed;
    width:100%;
}
.timeline .time-show {
    margin-right:-75px;
    margin-top:30px;
    position:relative;
    margin-bottom:30px;
}

.timeline .time-show a {
    color:#fff;
}

.timeline:before {
    background-color:#d8d9df;
    bottom:0px;
    content:"";
    left:50%;
    position:absolute;
    top:30px;
    width:1px;
    z-index:0;
}
h3.timeline-title {
    margin:0;
    color:#C8CCD7;
    font-size:20px;
    font-weight:400;
    margin:0 0 5px;
    text-transform:uppercase;
}
.t-info {
    color:#C8CCD7;
}
.timeline-item:before,
.timeline-item.alt:after {
    content:"";
    display:block;
    width:50%;
}
.timeline-item {
    display:table-row;
    margin-bottom: 50px;
    display: inline-table;
    width: 100%;
}
.timeline-desk {
    display:table-cell;
    vertical-align:top;
    width:50%;
    position:relative;
}
.timeline-desk h1 {
    font-size:16px;
    font-weight:400;
    margin:0 0 5px;
}
.timeline-desk .panel {
    display:block;
    margin-left:45px;
    text-align:left;
    background:none;
    box-shadow: none;
    border-radius: 0;
}

.timeline .timeline-icon {
    left:-35px;
    position:absolute;
    top:5px;
}
.timeline .timeline-icon {
    background:#C7CBD6;
}
.timeline-desk span a {
    text-transform:uppercase;
}


.timeline .timeline-icon {
    background:#222;
    border: 5px solid #fff;
}

.timeline .timeline-icon.light {
    background:#fff;
}

.timeline .timeline-icon {
    border-radius:50%;
    -webkit-border-radius:50%;
    display:block;
    height:70px;
    width:70px;
    line-height: 65px;
    text-align:center;
    color:#fff;
    font-size: 30px;
}

.timeline .timeline-icon i {
    margin-top:9px;
}

.timeline-item.alt .timeline-icon {
    left:auto;
    right:-35px;
}

.timeline .time-icon:before {
    font-size:16px;
    margin-top:5px;
}

.timeline .timeline-date {
    left:-210px;
    position:absolute;
    text-align:right;
    top:30px;
    width:150px;
    display:none;
}

.timeline-item.alt .timeline-date {
    left:auto;
    right:-210px;
    text-align:left;
    display:none;
}

.timeline-desk h5 span {
    color:#999999;
    display:block;
    font-size:12px;
    margin-bottom:4px;
}

.timeline-item.alt:before {
    display:none;
}
.timeline-item:before,.timeline-item.alt:after {
    content:"";
    display:block;
    width:50%;
}

.timeline-desk p {
    font-size:14px;
    margin-bottom:0;
    color:#999;
}

.timeline-desk a {

}

.timeline-desk .panel {
    margin-bottom:5px;
}

.timeline-desk .album {
    margin-top:20px;
}

.timeline-item.alt .timeline-desk .album {
    margin-top:20px;
    float:right;
}

.timeline-desk .album a {
    margin-right:5px;
    float:left;
}

.timeline-item.alt .timeline-desk .album a {
    margin-left:5px;
    float:right;
}

.timeline-desk .notification {
    background:none repeat scroll 0 0 #FFFFFF;
    margin-top:20px;
    padding:8px;
}

.timeline-item.alt .panel {
    margin-left:0;
    margin-right:45px;
}

.timeline-item.alt h1,.timeline-item.alt p {
    text-align: right;
}


/*----*/

.timeline.dark {

}

.timeline.dark:before {
    background-color:#fff;
    bottom:0px;
    content:"";
    left:50%;
    position:absolute;
    top:30px;
    width:1px;
    z-index:0;
}
.timeline.dark h3.timeline-title {
    margin:0;
    color:#333;
    font-size:20px;
    font-weight:400;
    margin:0 0 5px;
    text-transform:uppercase;
}

.timeline.dark .timeline-desk h1 {
    color: #fff;
}

.timeline.dark .timeline-desk p {
    color: #7e7e7e;
}

.timeline.dark .timeline-icon {
    background: #fff;
    border: 5px solid #000;
}

.timeline.dark .timeline-icon {
    color: #222;
}

.timeline-parallax {
    background-image: url("../img/parallax/t-parallax.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}