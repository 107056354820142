body {
  color: #323232;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.5;

  -webkit-font-smoothing: antialiased;
     -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a:active,
a:focus {
  outline: auto 5px -webkit-focus-ring-color !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 30px 0;
  color: #333333;
  font-weight: normal;
  line-height: 1.5;
}

h1,
h2 {
  letter-spacing: 2px;
}

h3,
h4 {
  letter-spacing: 2px;
}

h5,
h6 {
  letter-spacing: 1px;
}

a {
  // text-decoration: none !important;
}

a:hover,
a:focus {
  color: #323232;
}

a,
.btn {
  -webkit-transition: all 0.3s;
     -moz-transition: all 0.3s;
          transition: all 0.3s;
}

a img,
iframe {
  border: none;
}

p {
  margin: 0 0 30px;
  color: #7e7e7e;
  line-height: 30px;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eeeeee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  border: 1px solid #ededed;
  border-radius: 0;
  background-color: #f9f9f9;
  color: #333333;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 13px;
  line-height: 1.42857143;
}

.parallax-window {
  padding: 150px 0;
  min-height: 400px;
  background: transparent;
}
