.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

// Transitions
.isotope,
.isotope .isotope-item {
  transition-duration: 0.8s;
}

.isotope {
  transition-property: height, width;
}

.isotope .isotope-item {
  transition-property: transform, opacity;
}

/*disabling Isotope CSS3 transitions */
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  transition-duration: 0s;
}

/* disable CSS transitions for containers with infinite scrolling*/
.isotope.infinite-scrolling {
  transition: none;
}
