
.ticket-register .form-control{
  border-radius: 5px;
  height: 40px;
  color: #A7A7A7;
}
.ticket-register .form-group,
.register-member a {
  margin: 0 5px;
}


.register-member a {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;

}

.register-member a img{
  width: 100%;
  height: auto;
  border-radius: 50%;
}
