/*------------------------------------------------------------------
    divider
-------------------------------------------------------------------*/

.divider {
    width: 100%;
    margin: 50px 0;
    position: relative;
    color: #e2e2e2;
    display: inline-block;
}

.divider.d-single {
    width: 100%;
    height: 1px;
    border-color: #e2e2e2;
    border-top-width: 1px;
}

.divider.d-double {
    width: 100%;
    height: 8px;
    border-color: #e2e2e2;
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.d-single.d-solid {
    border-top-style: solid;
}

.d-single.d-dashed {
    border-top-style: dashed;
}

.d-double.d-solid {
    border-top-style: solid;
    border-bottom-style: solid;
}

.d-double.d-dashed {
    border-top-style: dashed;
    border-bottom-style: dashed;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
    display: inline-block;
    position: absolute;
    top: -5px;
    background: #fff;
}

.text-center .dot {
    margin-left: -17px;
}

.dot:before {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 12px;
    background: #fff;
    content: '';
}

.dot:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 25px;
    background: #fff;
    content: '';
}


.text-right .dot:before {
    left: -13px;
}

.text-right .dot:after {
    left: -25px;
}

.divider-small {
    width: 40% !important;
}

.divider-small.text-center {
    margin: 50px auto;
    display: inherit;
}

.divider-small.text-right {
    float: right;
}

.divider i {
    font-size: 18px;
    position: absolute;
    top: -10px;
    background: #fff;
    width: 30px;
}

.d-round i {
    width: 30px;
    height: 30px;
    background: #F5F5F5;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    text-align: center;
    line-height: 30px;
    color: #b4b4b4;
    font-size: 15px;
}

.d-border i {
    width: 30px;
    height: 30px;
    border:1px solid #e2e2e2;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
}